






















































































































































import { Component, Vue } from 'vue-property-decorator'
import ProfileInfo from '@/components/profile/ProfileInfo.vue'
import Info from '@/components/common/Info.vue'
import SubAccountsTable from '@/components/profile/SubAccountsTable.vue'
import AddSubAccountForm from '@/components/profile/AddSubAccountForm.vue'
import DialogInfo from '@/components/dialogs/DialogInfo.vue'
import EditProfileForm from '@/components/profile/EditProfileForm.vue'
import { Message } from 'element-ui'
import { UserResponseType } from '@/types/user'
import ChangePasswordForm from '@/components/profile/ChangePasswordForm.vue'
import { UsersListItemType } from '@/types/users'
import UsersCard from '@/components/mobile/profile/UsersCard.vue'
import Drawer from '@/components/common/Drawer.vue'
import UserDetail from '@/components/mobile/profile/UserDetail.vue'
import ReasonDeactivateFrom from '@/components/profile/ResonDeactivateForm.vue'

@Component({
  components: {
    UserDetail,
    Drawer,
    UsersCard,
    ChangePasswordForm,
    EditProfileForm,
    DialogInfo,
    SubAccountsTable,
    ProfileInfo,
    Info,
    AddSubAccountForm,
    ReasonDeactivateFrom,
  },
})
export default class Profile extends Vue {
  loading = false
  loadingInfo = false

  page = 1

  showAddSubAccount = false
  addSubAccountInfo = false
  showEditProfile = false
  showChangePassword = false
  reasonDeactivation = false

  showUserDetail = false

  subAccountEmail = ''
  id = 0

  get usersList(): UsersListItemType {
    return this.$store.getters['users/usersList']
  }

  get userDetail(): UsersListItemType {
    return this.$store.getters['users/usersDetail']
  }

  get profileInfo(): UserResponseType {
    return this.$store.getters['user/user']
  }

  get usersTotal(): string {
    return this.$store.getters['users/usersTotal']
  }

  get showedUsersCount() {
    if (this.usersTotal) {
      return 10 * this.page > parseInt(this.usersTotal)
        ? this.usersTotal
        : 10 * this.page
    } else {
      return 0
    }
  }

  handleShowDialog(): void {
    this.showAddSubAccount = !this.showAddSubAccount
  }

  async handleReasonDeactivationClose(id: number): Promise<void> {
    this.reasonDeactivation = !this.reasonDeactivation

    this.id = id
  }

  async handleShowInfo(email: string): Promise<void> {
    this.subAccountEmail = email

    this.addSubAccountInfo = !this.addSubAccountInfo

    await this.getUsers()
  }

  handleShowEditProfileForm(): void {
    this.showEditProfile = !this.showEditProfile
    this.$store.dispatch('user/getUserData')
  }

  handleEditProfile(success: boolean): void {
    if (success) {
      Message.success('Изменения в профиле сохранены')
    } else {
      Message.error('Изменения не сохранены')
    }

    this.handleShowEditProfileForm()
  }

  handleShowChangePasswordForm(): void {
    this.showChangePassword = !this.showChangePassword
  }

  handleChangePassword(success: boolean): void {
    if (success) {
      Message.success('Пароль изменен')
    } else {
      Message.error('Пароль не изменен')
    }

    this.handleShowChangePasswordForm()
  }

  async handleShowUserDetail(id: number): Promise<void> {
    this.loadingInfo = true
    this.showUserDetail = !this.showUserDetail

    await this.$store.dispatch('users/getUsersDetail', id)

    this.loadingInfo = false
  }

  async getUsers(): Promise<void> {
    this.loading = true

    await this.$store.dispatch('user/getUserData')
    await this.$store.dispatch('users/getUsersList')

    this.loading = false
  }

  async mounted(): Promise<void> {
    await this.getUsers()
  }
}
